@charset "UTF-8";
@import url(../../assets/bower_components/normalize.css/normalize.css);
/* Slider */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&subset=latin-ext");
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  /*
    img {
        display: block;
    }
    */
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px\9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

body {
  font: 16px/1.5 "Open Sans", sans-serif; }

#__bs_notify__ {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -125px !important;
  width: 250px;
  height: 50px;
  border-radius: 0 !important;
  opacity: 0.5; }

.center {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .center::after {
    clear: both;
    content: "";
    display: table; }

.clearfix::after {
  clear: both;
  content: "";
  display: table; }

::selection {
  background: #cccccc;
  text-shadow: none; }

.t-Title, .t-Title--with-MarginBottom {
  text-transform: uppercase;
  font-size: 2.25em;
  line-height: 44px;
  letter-spacing: 3.5px;
  margin-top: 100px;
  font-weight: 300;
  margin-bottom: 0;
  text-align: center; }
  .t-Title--with-MarginBottom {
    margin-bottom: 70px; }

.t-input-Select {
  background: #777;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  transition: 200ms;
  backface-visibility: hidden;
  margin-right: 10px; }
  .t-input-Select option {
    background: transparent; }
  .t-input-Select:hover {
    background: #6a6a6a; }
    .t-input-Select:hover:after {
      background-color: #6a6a6a; }
  .t-input-Select:active {
    transform: scale(0.97);
    transition: 60ms; }
  .t-input-Select:after {
    display: block;
    content: "";
    position: absolute;
    width: 40px;
    height: 100%;
    line-height: 43px;
    font-size: 0.75em;
    color: #999999;
    top: 0;
    right: 0;
    text-align: center;
    background: no-repeat center center;
    background-color: #777;
    pointer-events: none;
    transition: 200ms; }
  .t-input-Select select {
    border: 0;
    -webkit-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    padding: 9px 10px 8px;
    outline: none;
    cursor: pointer;
    color: #4d4d4d;
    display: block;
    background: #FFF;
    background: transparent;
    padding-right: 40px;
    display: block; }
  .t-input-Select select[disabled] {
    color: rgba(0, 0, 0, 0.3); }

.t-MoreButton {
  border: 1px #FFF solid;
  display: inline-block;
  padding: 0 30px;
  line-height: 46px;
  text-decoration: none;
  letter-spacing: 2.5px;
  font-size: 14px;
  text-transform: uppercase;
  color: #FFF;
  position: relative;
  overflow: hidden;
  z-index: 1;
  vertical-align: bottom;
  background: transparent;
  transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
  .t-MoreButton:hover {
    color: #4c68b2; }
    .t-MoreButton:hover:before {
      opacity: 1;
      right: -100px;
      left: -100px; }
  .t-MoreButton:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    top: -10px;
    bottom: -10px;
    transform: skew(0deg);
    background: #FFF;
    left: 0;
    right: 0;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0; }

img {
  max-width: 100%;
  height: auto; }

a {
  cursor: pointer; }

img.alignright {
  float: right;
  margin: 0 0 1em 1em; }

img.alignleft {
  float: left;
  margin: 0 1em 1em 0; }

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.alignright {
  float: right; }

.alignleft {
  float: left; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.navigation li.active a {
  color: #8C001A; }

.mobile {
  display: none; }

.homeSlider {
  float: left;
  width: 100%;
  height: 100vh;
  margin-bottom: 0px !important;
  margin-top: 100px; }
  .homeSlider .center {
    position: relative;
    height: 100vh; }
  .homeSlider__Slide {
    float: left;
    height: 100vh;
    width: 100%;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    position: relative;
    z-index: 1;
    position: relative;
    z-index: 1; }
    .homeSlider__Slide:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: -1; }
  .homeSlider__Inner {
    float: left;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    text-align: center;
    padding-left: 50px;
    padding-right: 50px; }
    .homeSlider__Inner a {
      text-decoration: none; }
      .homeSlider__Inner a:hover h2:before {
        opacity: 1; }
    .homeSlider__Inner h2 {
      font-weight: 700;
      font-size: 50px;
      text-align: center;
      line-height: 1.1;
      color: #FFF;
      margin: 0 auto;
      margin-bottom: -300px;
      transition: 300ms; }
      .homeSlider__Inner h2:before {
        position: absolute;
        content: '';
        display: block;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        height: 7px;
        opacity: 0;
        background: #4c68b2;
        transition: 300ms; }
    .homeSlider__Inner h3 {
      font-weight: 700;
      font-size: 30px;
      text-align: center;
      line-height: 1.1;
      color: #FFF;
      margin-bottom: -300px;
      transition: 300ms; }
      .homeSlider__Inner h3:before {
        position: absolute;
        content: '';
        display: block;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        height: 7px;
        opacity: 0;
        background: #4c68b2;
        transition: 300ms; }
    .homeSlider__Inner a {
      border: 1px #FFF solid;
      display: inline-block;
      padding: 0 30px;
      line-height: 46px;
      text-decoration: none;
      letter-spacing: 2.5px;
      font-size: 14px;
      text-transform: uppercase;
      color: #FFF;
      position: relative;
      overflow: hidden;
      z-index: 1;
      vertical-align: bottom;
      background: #FFF;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      color: #4c68b2;
      margin-top: 50px; }
      .homeSlider__Inner a:hover {
        color: #4c68b2; }
        .homeSlider__Inner a:hover:before {
          opacity: 1;
          right: -100px;
          left: -100px; }
      .homeSlider__Inner a:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        top: -10px;
        bottom: -10px;
        transform: skew(0deg);
        background: #FFF;
        left: 0;
        right: 0;
        transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
        opacity: 0; }
      .homeSlider__Inner a:before {
        background: #4c68b2; }
      .homeSlider__Inner a:hover {
        color: #FFF; }
  .homeSlider .slick-active h2 {
    margin: 0 auto;
    transition: 300ms; }
  .homeSlider .slick-active h3 {
    margin-bottom: 0;
    transition: 300ms; }

.arrowPrev, .arrowNext {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px;
  height: 100%; }

.arrowPrev {
  height: 100vh;
  line-height: 100vh !important;
  opacity: 1;
  transition: 200ms;
  color: #FFF;
  font-size: 80px !important;
  padding-left: 15px;
  z-index: 10; }
  .arrowPrev:hover {
    opacity: 0.8;
    transition: 200ms; }

.arrowNext {
  height: 100vh;
  line-height: 100vh !important;
  opacity: 1;
  transition: 200ms;
  color: #FFF;
  font-size: 80px !important;
  opacity: 1;
  transition: 200ms;
  right: 0;
  text-align: right;
  padding-right: 15px;
  z-index: 10; }
  .arrowNext:hover {
    opacity: 0.8;
    transition: 200ms; }

.news {
  float: left;
  width: 100%;
  text-align: center;
  padding: 60px 0;
  padding-top: 0; }
  .news h2 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #4c68b2;
    margin: 0;
    text-transform: uppercase; }
  .news h5 {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin-top: 0;
    color: #777; }
  .news__Box {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 31.03448%;
    background: #ededed; }
    .news__Box:last-child {
      margin-right: 0; }
    .news__Box:nth-of-type(3n) {
      margin-right: 0; }
    .news__Box--img {
      float: left;
      width: 100%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; }
    .news__Box--cnt {
      float: left;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%; }
    .news__Box a {
      text-decoration: none; }
      .news__Box a:hover p {
        color: #000; }
    .news__Box h4 {
      float: left;
      width: 100%;
      color: #4c68b2;
      font-size: 18px; }
    .news__Box p {
      color: #777;
      font-size: 14px; }
  .news__More {
    float: left;
    width: 100%; }
    .news__More a {
      text-decoration: none;
      font-size: 76px;
      font-weight: 700;
      text-align: center;
      color: #4c68b2;
      position: relative;
      z-index: 1;
      transition: 300ms; }
      .news__More a:before {
        height: 10px;
        background: #4c68b2;
        left: 0;
        bottom: 20px;
        right: 100%;
        position: absolute;
        content: '';
        z-index: -1;
        transition: 300ms; }
      .news__More a:hover:before {
        right: 0; }

.addTopPadding {
  padding-top: 60px !important; }

.page-template-page-news .news__Box {
  margin-bottom: 3.44828%; }

.widgets {
  float: left;
  width: 100%;
  text-align: center;
  padding: 60px 0 0 0; }
  .widgets h2 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #4c68b2;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase; }
  .widgets h5 {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin-top: 0;
    color: #777; }
  .widgets__Box {
    float: left;
    width: 32%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    height: 230px;
    overflow: hidden;
    position: relative;
    padding-top: 30px; }
    .widgets__Box h4 {
      float: left;
      clear: both;
      width: 100%;
      text-align: center;
      color: #777;
      position: relative; }
    .widgets__Box img {
      transition: 300ms;
      max-width: 150px; }
    .widgets__Box a {
      float: left;
      width: 100%;
      height: 230px;
      transition: 300ms;
      text-decoration: none; }
      .widgets__Box a:hover img {
        transform: scale(1.1); }

.sectionTitle {
  float: left;
  width: 100%; }
  .sectionTitle h2 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #4c68b2;
    text-transform: uppercase; }

.acf-map {
  float: left;
  width: 100%;
  height: 500px; }

.references {
  float: left;
  width: 100%;
  text-align: center;
  padding: 0; }
  .references h2 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #4c68b2;
    margin: 0;
    text-transform: uppercase; }
  .references__Box {
    float: left;
    width: 25%;
    height: 190px !important; }
    .references__Box--name {
      float: left;
      width: 100%;
      text-align: center;
      height: 200px;
      position: relative; }
      .references__Box--name img {
        max-width: 180px;
        text-align: center;
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.5;
        transition: 300ms; }
      .references__Box--name h4 {
        float: left;
        clear: both;
        width: 100%;
        margin-bottom: 0;
        color: #4c68b2;
        position: absolute;
        bottom: 0;
        transition: 300ms;
        line-height: 1.1; }
      .references__Box--name h6 {
        float: left;
        clear: both;
        width: 100%;
        margin-top: 0;
        text-transform: uppercase;
        color: #4c68b2;
        transition: 300ms;
        position: absolute;
        bottom: -50px; }
    .references__Box a {
      float: left;
      width: 100%;
      height: 300px;
      transition: 300ms;
      text-decoration: none;
      z-index: 100;
      position: relative; }
      .references__Box a:hover img {
        opacity: 1;
        max-width: 95px; }
      .references__Box a:hover h4 {
        color: #4c68b2; }
      .references__Box a:hover h6 {
        color: #4c68b2; }
      .references__Box a:hover p {
        opacity: 1; }
  .references__More a {
    border: 1px #4c68b2 solid;
    display: inline-block;
    padding: 0 30px;
    line-height: 46px;
    text-decoration: none;
    letter-spacing: 2.5px;
    font-size: 14px;
    text-transform: uppercase;
    color: #4c68b2;
    position: relative;
    overflow: hidden;
    z-index: 1;
    vertical-align: bottom;
    background: #4c68b2;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
    color: #FFF;
    right: -5px; }
    .references__More a:hover {
      color: #4c68b2; }
      .references__More a:hover:before {
        opacity: 1;
        right: -100px;
        left: -100px; }
    .references__More a:before {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      top: -10px;
      bottom: -10px;
      transform: skew(0deg);
      background: #FFF;
      left: 0;
      right: 0;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      opacity: 0; }

.arrowPrev-reference, .arrowNext-reference {
  display: block;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 10000;
  transform: translateY(-50%); }
  .arrowPrev-reference:after, .arrowNext-reference:after {
    display: none; }

.arrowPrev-reference {
  opacity: 1;
  transition: 200ms;
  color: #4c68b2;
  font-size: 40px;
  padding-left: 15px;
  left: -20px; }
  .arrowPrev-reference:hover {
    opacity: 0.8;
    transition: 200ms; }
  .arrowPrev-reference:before {
    line-height: 300px;
    font-size: 26px !important; }

.arrowNext-reference {
  transition: 200ms;
  color: #4c68b2;
  font-size: 40px;
  opacity: 1;
  transition: 200ms;
  right: 0px;
  text-align: right;
  padding-right: 15px; }
  .arrowNext-reference:hover {
    opacity: 0.8;
    transition: 200ms; }
  .arrowNext-reference:before {
    line-height: 300px;
    font-size: 26px !important; }

.aboutUs {
  float: left;
  width: 100%;
  padding: 60px 0 0 0; }
  .aboutUs__Slider {
    float: left;
    width: 100%;
    height: 450px;
    background: #4c68b2;
    margin-bottom: 0 !important; }
  .aboutUs__Slide {
    float: left;
    width: 100%;
    height: 450px !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1; }
    .aboutUs__Slide:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #4c68b2;
      opacity: 0.7; }
  .aboutUs__Content {
    float: left;
    width: 100%;
    text-align: center;
    height: 450px;
    margin-top: -380px; }
    .aboutUs__Content h2 {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      color: #FFF;
      margin: 0;
      text-transform: uppercase; }
    .aboutUs__Content p {
      color: #FFF;
      font-size: 18px; }

.innerImage {
  float: left;
  width: 100%;
  height: 600px;
  margin-bottom: 60px; }
  .innerImage .center {
    height: 600px; }
  .innerImage__Slide {
    float: left;
    height: 600px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1; }
    .innerImage__Slide:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: '';
      background: linear-gradient(0deg, #000, transparent);
      opacity: 0.7; }
  .innerImage__Inner {
    float: left;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: relative; }
    .innerImage__Inner a {
      text-decoration: none; }
      .innerImage__Inner a:hover h2:before {
        opacity: 1; }
    .innerImage__Inner h2 {
      font-weight: 700;
      font-size: 80px;
      text-align: left;
      line-height: 1.1;
      color: #FFF;
      transition: 300ms;
      margin-bottom: 0; }

.blogImage {
  float: left;
  width: 100%;
  height: 600px; }
  .blogImage .center {
    height: 600px; }
  .blogImage__Slide {
    float: left;
    height: 600px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1; }
    .blogImage__Slide:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: '';
      background: linear-gradient(0deg, #000, transparent);
      opacity: 0.7; }
  .blogImage__Inner {
    float: left;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: relative; }
    .blogImage__Inner a {
      text-decoration: none; }
      .blogImage__Inner a:hover h2:before {
        opacity: 1; }
    .blogImage__Inner h2 {
      font-weight: 700;
      font-size: 80px;
      text-align: left;
      line-height: 1.1;
      color: #FFF;
      transition: 300ms;
      margin-bottom: 0; }

.productImage {
  float: left;
  width: 100%;
  height: 600px;
  margin-bottom: 60px; }
  .productImage__Slide {
    float: left;
    height: 600px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1; }
    .productImage__Slide:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: '';
      background: linear-gradient(0deg, #000, transparent);
      opacity: 0.7; }
  .productImage__Inner {
    float: left;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    padding: 50px; }
    .productImage__Inner a {
      text-decoration: none; }
      .productImage__Inner a:hover h2:before {
        opacity: 1; }
    .productImage__Inner h2 {
      font-weight: 700;
      font-size: 80px;
      text-align: left;
      line-height: 1.1;
      color: #FFF;
      transition: 300ms;
      margin-bottom: 0; }

.errorImage {
  float: left;
  width: 100%;
  height: 600px;
  margin-top: 130px; }
  .errorImage .center {
    position: relative;
    height: 600px; }
  .errorImage__Slide {
    float: left;
    height: 600px;
    width: 100%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right center;
    position: relative;
    z-index: 1;
    background-color: #4c68b2; }
    .errorImage__Slide:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: '';
      background: linear-gradient(0deg, #000, transparent);
      opacity: 0.7; }
  .errorImage__Inner {
    float: left;
    width: 100%;
    max-width: 600px;
    top: 50%;
    transform: translateY(-50%);
    position: relative; }
    .errorImage__Inner a {
      text-decoration: none; }
      .errorImage__Inner a:hover h2:before {
        opacity: 1; }
    .errorImage__Inner h2 {
      font-weight: 700;
      font-size: 80px;
      text-align: left;
      line-height: 1.1;
      color: #FFF;
      transition: 300ms; }
      .errorImage__Inner h2 span {
        display: block;
        font-size: 38px;
        font-weight: 300; }

.content {
  margin: auto;
  float: left;
  width: 100%; }
  .content__Block {
    float: left;
    width: 50%;
    padding: 0 20px; }
  .content h1 {
    font-size: 60px;
    font-weight: 900;
    color: #4c68b2;
    line-height: 1.1; }
  .content h2 {
    font-size: 50px;
    font-weight: 900;
    color: #4c68b2;
    line-height: 1.1; }
  .content h3 {
    font-size: 40px;
    font-weight: 700;
    color: #4c68b2;
    line-height: 1.1; }
  .content h4 {
    font-size: 30px;
    font-weight: 500;
    color: #4c68b2;
    line-height: 1.1; }
  .content h5 {
    font-size: 20px;
    font-weight: 500;
    color: #4c68b2;
    line-height: 1.1; }
  .content h6 {
    font-size: 16px;
    font-size: 700;
    color: #777;
    line-height: 1.1;
    text-transform: uppercase; }
  .content li {
    color: #999;
    font-weight: 300; }
  .content mark {
    background: rgba(153, 153, 153, 0.3); }
  .content blockquote {
    background: rgba(153, 153, 153, 0.3);
    font-weight: 300;
    font-size: 14px;
    padding: 20px; }
  .content p {
    font-size: 18px;
    font-weight: 300;
    color: rgba(119, 119, 119, 0.8); }
  .content a {
    text-decoration: none;
    color: #4c68b2;
    position: relative;
    z-index: 1;
    transition: 300ms; }
    .content a:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
      height: 1px;
      background: #4c68b2;
      z-index: -1;
      opacity: 0;
      transition: 300ms; }
    .content a:hover {
      color: #000;
      transition: 300ms; }
      .content a:hover:after {
        opacity: 1;
        bottom: 0px;
        transition: 300ms; }
  .content input {
    width: 90%;
    height: 50px;
    padding: 10px; }
  .content input[type="submit"] {
    background: #4c68b2;
    color: #FFF;
    border: 0; }
  .content textarea {
    min-width: 90%;
    max-width: 90%;
    width: 90%;
    padding: 10px; }

.blog {
  float: left;
  width: 100%;
  text-align: center;
  padding-bottom: 70px;
  margin-top: 60px; }
  .blog h2 {
    font-size: 76px;
    font-weight: 700;
    text-align: center;
    color: #4c68b2;
    margin-top: 0;
    margin-bottom: 100px; }
  .blog h5 {
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    margin-top: 0;
    margin-bottom: 60px;
    color: #FFF; }
  .blog__Box {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 22.41379%;
    position: relative;
    z-index: 1;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 3.44828%; }
    .blog__Box:last-child {
      margin-right: 0; }
    .blog__Box:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: -1; }
    .blog__Box:nth-of-type(3n) {
      margin-right: 0; }
    .blog__Box:nth-of-type(2n) {
      float: left;
      display: block;
      margin-right: 3.44828%;
      width: 48.27586%; }
      .blog__Box:nth-of-type(2n):last-child {
        margin-right: 0; }
    .blog__Box:nth-of-type(6n) {
      float: left;
      display: block;
      margin-right: 3.44828%;
      width: 22.41379%;
      margin-right: 0; }
      .blog__Box:nth-of-type(6n):last-child {
        margin-right: 0; }
    .blog__Box h4 {
      float: left;
      clear: both;
      width: 100%;
      text-align: left;
      padding-left: 20px;
      margin-bottom: 0;
      color: #FFF; }
    .blog__Box h6 {
      float: left;
      clear: both;
      width: 100%;
      text-align: left;
      padding-left: 20px;
      margin-top: 0;
      text-transform: uppercase;
      color: #FFF;
      transition: 300ms; }
    .blog__Box a {
      float: left;
      width: 100%;
      height: 400px;
      transition: 300ms;
      text-decoration: none; }
      .blog__Box a:before {
        transition: 300ms;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        z-index: -1;
        opacity: 0; }
      .blog__Box a:hover h6 {
        color: #4c68b2; }
      .blog__Box a:hover p {
        opacity: 1; }
      .blog__Box a:hover:before {
        opacity: 0.5; }
    .blog__Box p {
      color: #FFF;
      font-size: 13px;
      transition: 300ms;
      position: relative;
      padding: 20px;
      text-align: left;
      float: left;
      padding-top: 0;
      opacity: 0;
      transition: 300ms; }
  .blog__More {
    float: left;
    width: 100%; }
    .blog__More a {
      text-decoration: none;
      font-size: 76px;
      font-weight: 700;
      text-align: center;
      color: #4c68b2;
      position: relative;
      z-index: 1;
      transition: 300ms; }
      .blog__More a:before {
        height: 10px;
        background: #4c68b2;
        left: 0;
        bottom: 20px;
        right: 100%;
        position: absolute;
        content: '';
        z-index: -1;
        transition: 300ms; }
      .blog__More a:hover:before {
        right: 0; }

.contactBlock {
  margin-top: 60px; }

.innerSlider {
  float: left;
  width: 100%;
  overflow: hidden;
  height: 900px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url(../images/bg-onama-top.png); }
  .innerSlider__Left {
    width: 30%;
    padding-left: 15%;
    text-align: right;
    padding-right: 7%;
    position: relative;
    display: table-cell;
    vertical-align: bottom;
    height: 550px; }
    .innerSlider__Left img {
      max-width: 100px; }
    .innerSlider__Left h3 {
      color: #FFF;
      font-size: 40px;
      text-transform: uppercase;
      line-height: 1.2;
      margin-bottom: 0; }
  .innerSlider__Right {
    width: 40%;
    padding-right: 10%;
    padding-left: 2%;
    position: relative;
    display: table-cell;
    vertical-align: bottom;
    height: 550px;
    right: 5%; }
    .innerSlider__Right p {
      text-align: justify;
      color: #FFF;
      margin: 0;
      line-height: 1.2;
      font-weight: 300; }
    .innerSlider__Right h4 {
      color: #FFF;
      margin: 0;
      margin-top: 10px;
      text-transform: uppercase; }
  .innerSlider__Statistics {
    background: rgba(0, 0, 0, 0.7);
    float: left;
    width: 100%;
    height: 250px;
    margin-top: 50px; }
    .innerSlider__Statistics .circliful {
      float: right;
      font-weight: 300; }
    .innerSlider__Statistics .circle-text {
      color: #FFF;
      font-weight: 300; }
      .innerSlider__Statistics .circle-text i {
        display: none; }
    .innerSlider__Statistics .filled .circle-text {
      color: #000; }
    .innerSlider__Statistics .circle-info {
      top: 150px;
      color: #FFF;
      line-height: 1.2 !important; }
    .innerSlider__Statistics--wrap {
      height: 250px;
      float: left;
      width: 100%;
      position: relative;
      z-index: 1; }
      .innerSlider__Statistics--wrap:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 0;
        height: 0;
        top: 0px;
        left: 10%;
        border-style: solid;
        border-width: 20px 20px 0 20px;
        border-color: #cf212e transparent transparent;
        transform: translateX(-50%);
        opacity: 1;
        transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
    .innerSlider__Statistics--left {
      float: left;
      width: 30%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: #FFF;
      font-weight: 300; }
    .innerSlider__Statistics--right {
      float: left;
      width: 70%;
      height: 200px;
      padding-top: 25px; }

.page-template-page-homepage .menu li:first-of-type a:before {
  opacity: 1;
  transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }

.page-template-page-about .menu li:first-of-type a:before {
  opacity: 1;
  transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }

.contactPage {
  height: 500px;
  float: left;
  width: 100%;
  position: relative; }
  .contactPage__Right {
    float: right;
    height: 500px;
    width: 1000px;
    position: absolute;
    top: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(../images/bg-onama-contact.png); }
  .contactPage__Inner {
    width: 60%;
    float: right;
    transform: translateY(-50%);
    top: 50%;
    position: relative; }
    .contactPage__Inner img {
      max-width: 90px; }
    .contactPage__Inner ul {
      padding: 0;
      margin: 0;
      margin-top: 20px; }
      .contactPage__Inner ul li {
        list-style: none;
        color: #FFF;
        text-transform: uppercase; }
        .contactPage__Inner ul li a {
          text-decoration: none;
          color: #FFF;
          margin-left: 5px;
          margin-right: 5px; }
        .contactPage__Inner ul li a.mail {
          color: #4c68b2;
          text-transform: lowercase; }
    .contactPage__Inner ul.social {
      padding: 0;
      margin: 0;
      margin-top: 40px; }
      .contactPage__Inner ul.social li {
        list-style-type: none;
        text-align: left;
        display: inline-block;
        color: #FFF;
        height: 30px;
        width: 30px;
        border: 1px solid #FFF;
        border-radius: 100%;
        text-align: center;
        line-height: 30px; }
        .contactPage__Inner ul.social li i {
          font-size: 16px; }

.map {
  float: left;
  width: 100%;
  height: 500px;
  transform: translateX(-10%); }

.productsPage {
  float: left;
  width: 100%;
  position: relative;
  z-index: 1; }
  .productsPage__Wrap {
    float: left;
    width: 100%;
    background: #FFF; }
  .productsPage__Box {
    float: left;
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 48.27586%;
    height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1;
    transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
    margin-bottom: 50px; }
    .productsPage__Box:last-child {
      margin-right: 0; }
    .productsPage__Box:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.75);
      z-index: -1;
      transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
    .productsPage__Box:nth-of-type(2n) {
      margin-right: 0; }
    .productsPage__Box h4 {
      font-size: 14px;
      color: #999;
      margin: 0;
      transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
      font-weight: 300; }
    .productsPage__Box h3 {
      color: #4c68b2;
      font-size: 20px;
      margin: 0;
      transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
      text-transform: uppercase;
      word-spacing: 500px; }
    .productsPage__Box i {
      color: #4c68b2;
      font-size: 40px; }
    .productsPage__Box a {
      float: left;
      width: 100%;
      height: 300px;
      text-decoration: none;
      position: relative;
      padding: 0 50px;
      transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
    .productsPage__Box--wrapText {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      float: left;
      width: 50%;
      text-align: left; }
    .productsPage__Box--wrapRight {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      float: left;
      width: 50%;
      text-align: right; }
    .productsPage__Box:hover {
      transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
      .productsPage__Box:hover a {
        padding-left: 70px;
        padding-right: 70px;
        transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
      .productsPage__Box:hover h4 {
        color: #FFF;
        transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
      .productsPage__Box:hover h3 {
        color: #FFF;
        transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
      .productsPage__Box:hover i {
        color: #FFF;
        transform: rotate(180deg); }
      .productsPage__Box:hover:after {
        transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
        background: rgba(76, 104, 178, 0.75); }

.productsList {
  float: left;
  width: 100%;
  text-align: center;
  padding-bottom: 50px; }
  .productsList__Box {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 22.41379%;
    height: 350px;
    background-size: 90% auto;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: 300ms; }
    .productsList__Box:last-child {
      margin-right: 0; }
    .productsList__Box img {
      transition: 300ms; }
    .productsList__Box:hover .productsList__Box--image {
      transform: scale(1.05); }
    .productsList__Box:nth-of-type(4n) {
      margin-right: 0; }
    .productsList__Box h4 {
      float: left;
      clear: both;
      width: 100%;
      text-align: center;
      color: #4c68b2;
      font-size: 20px;
      font-weight: 700;
      position: relative;
      transition: 300ms;
      opacity: 1; }
    .productsList__Box a {
      float: left;
      width: 100%;
      height: 350px;
      transition: 300ms;
      text-decoration: none;
      position: relative; }
    .productsList__Box p {
      color: #FFF;
      font-size: 13px;
      transition: 300ms;
      position: relative;
      padding: 20px;
      text-align: left;
      float: left;
      padding-top: 0;
      opacity: 0;
      transition: 300ms; }
    .productsList__Box--image {
      float: left;
      width: 100%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      transition: 300ms; }
    .productsList__Box--content {
      float: left;
      width: 100%; }

.singleProduct {
  float: left;
  width: 100%;
  padding-bottom: 50px; }
  .singleProduct__Block {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 48.27586%; }
    .singleProduct__Block:last-child {
      margin-right: 0; }
    .singleProduct__Block--content h1 {
      font-size: 60px;
      font-weight: 900;
      color: #4c68b2;
      line-height: 1.1;
      margin-top: 0; }
    .singleProduct__Block--content h2 {
      font-size: 50px;
      font-weight: 900;
      color: #4c68b2;
      line-height: 1.1;
      margin-top: 0; }
    .singleProduct__Block--content h3 {
      font-size: 40px;
      font-weight: 700;
      color: #4c68b2;
      line-height: 1.1;
      margin-top: 0; }
    .singleProduct__Block--content h4 {
      font-size: 30px;
      font-weight: 500;
      color: #4c68b2;
      line-height: 1.1;
      margin-top: 0; }
    .singleProduct__Block--content h5 {
      font-size: 20px;
      font-weight: 500;
      color: #4c68b2;
      line-height: 1.1;
      margin-top: 0; }
    .singleProduct__Block--content h6 {
      font-size: 16px;
      font-size: 700;
      color: #777;
      line-height: 1.1;
      text-transform: uppercase;
      margin-top: 0; }
    .singleProduct__Block--content li {
      color: #999;
      font-weight: 300; }
    .singleProduct__Block--content mark {
      background: rgba(153, 153, 153, 0.3); }
    .singleProduct__Block--content blockquote {
      background: rgba(153, 153, 153, 0.3);
      font-weight: 300;
      font-size: 14px;
      padding: 20px; }
    .singleProduct__Block--content p {
      font-size: 16px;
      font-weight: 300;
      color: rgba(119, 119, 119, 0.8); }
    .singleProduct__Block--content a {
      text-decoration: none;
      color: #4c68b2;
      position: relative;
      z-index: 1;
      transition: 300ms; }
      .singleProduct__Block--content a:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        height: 1px;
        background: #4c68b2;
        z-index: -1;
        opacity: 0;
        transition: 300ms; }
      .singleProduct__Block--content a:hover {
        color: #000;
        transition: 300ms; }
        .singleProduct__Block--content a:hover:after {
          opacity: 1;
          bottom: 0px;
          transition: 300ms; }
    .singleProduct__Block--content input {
      width: 90%;
      height: 50px;
      padding: 10px; }
    .singleProduct__Block--content input[type="submit"] {
      background: #4c68b2;
      color: #FFF;
      border: 0; }
    .singleProduct__Block--content textarea {
      min-width: 90%;
      max-width: 90%;
      width: 90%;
      padding: 10px; }

.singleSlider {
  float: left;
  width: 100%;
  height: 400px; }
  .singleSlider__Slide {
    float: left;
    width: 100%;
    height: 400px !important;
    min-height: 400px !important;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center; }

.singleSliderNav {
  float: left;
  height: 150px !important;
  width: 100%; }
  .singleSliderNav .slick-list {
    padding: 0 !important; }
  .singleSliderNav__Slide {
    float: left;
    height: 150px !important;
    margin-left: 0.5%;
    margin-right: 0.5%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.3; }
  .singleSliderNav .singleSliderNav__Slide.slick-slide.slick-current.slick-center {
    opacity: 1; }

.arrowPrev-product-single, .arrowNext-product-single {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px;
  height: 100%;
  z-index: 10000; }

.arrowPrev-product-single {
  opacity: 1;
  transition: 200ms;
  color: #FFF;
  font-size: 40px;
  padding-left: 15px; }
  .arrowPrev-product-single:hover:after {
    opacity: 0.5; }
  .arrowPrev-product-single:before {
    line-height: 400px;
    font-size: 35px !important;
    color: #4c68b2;
    z-index: 100; }
  .arrowPrev-product-single:after {
    left: -1px;
    width: 60px;
    background: #FFF;
    position: absolute;
    top: 0;
    bottom: 0;
    content: '';
    opacity: 0;
    transition: 300ms;
    z-index: 10; }

.arrowNext-product-single {
  transition: 200ms;
  color: #FFF;
  font-size: 40px;
  opacity: 1;
  transition: 200ms;
  right: 10px;
  text-align: right;
  padding-right: 15px; }
  .arrowNext-product-single:hover:after {
    opacity: 0.5; }
  .arrowNext-product-single:before {
    line-height: 400px;
    font-size: 35px !important;
    color: #4c68b2;
    z-index: 100; }
  .arrowNext-product-single:after {
    right: -10px;
    width: 60px;
    background: #FFF;
    position: absolute;
    top: 0;
    bottom: 0;
    content: '';
    opacity: 0;
    transition: 300ms;
    z-index: 10; }

.tabsBlock {
  float: left;
  width: 100%;
  background: #ededed;
  padding-bottom: 100px; }

.tabsNav {
  float: left;
  width: 100%;
  background: #FFF; }
  .tabsNav .slick-slider {
    margin-bottom: 0 !important; }
  .tabsNav__Slide {
    float: left;
    width: 25%;
    background: #FFF;
    text-align: center; }
    .tabsNav__Slide:hover {
      cursor: pointer; }
    .tabsNav__Slide h4 {
      font-weight: 300;
      font-size: 24px; }
  .tabsNav .tabsNav__Slide.slick-slide.slick-current.slick-center {
    background: #ededed !important;
    color: #4c68b2 !important; }

.tabsContent {
  float: left;
  width: 100%;
  background: #ededed;
  padding-top: 60px; }
  .tabsContent .slick-slider {
    margin-bottom: 0 !important; }
  .tabsContent__Slide {
    float: left;
    width: 100%;
    background: #ededed;
    color: rgba(119, 119, 119, 0.8); }
    .tabsContent__Slide h1 {
      font-size: 60px;
      font-weight: 900;
      color: #4c68b2;
      line-height: 1.1;
      margin-top: 0; }
    .tabsContent__Slide h2 {
      font-size: 50px;
      font-weight: 900;
      color: #4c68b2;
      line-height: 1.1;
      margin-top: 0; }
    .tabsContent__Slide h3 {
      font-size: 40px;
      font-weight: 700;
      color: #4c68b2;
      line-height: 1.1;
      margin-top: 0; }
    .tabsContent__Slide h4 {
      font-size: 30px;
      font-weight: 500;
      color: #4c68b2;
      line-height: 1.1;
      margin-top: 0; }
    .tabsContent__Slide h5 {
      font-size: 20px;
      font-weight: 500;
      color: #4c68b2;
      line-height: 1.1;
      margin-top: 0; }
    .tabsContent__Slide h6 {
      font-size: 16px;
      font-size: 700;
      color: #777;
      line-height: 1.1;
      text-transform: uppercase;
      margin-top: 0; }
    .tabsContent__Slide li {
      color: #999;
      font-weight: 300; }
    .tabsContent__Slide mark {
      background: rgba(153, 153, 153, 0.3); }
    .tabsContent__Slide blockquote {
      background: rgba(153, 153, 153, 0.3);
      font-weight: 300;
      font-size: 14px;
      padding: 20px; }
    .tabsContent__Slide p {
      font-size: 16px;
      font-weight: 300;
      color: rgba(119, 119, 119, 0.8); }
    .tabsContent__Slide a {
      text-decoration: none;
      color: #4c68b2;
      position: relative;
      z-index: 1;
      transition: 300ms; }
      .tabsContent__Slide a:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        height: 1px;
        background: #4c68b2;
        z-index: -1;
        opacity: 0;
        transition: 300ms; }
      .tabsContent__Slide a:hover {
        color: #000;
        transition: 300ms; }
        .tabsContent__Slide a:hover:after {
          opacity: 1;
          bottom: 0px;
          transition: 300ms; }
    .tabsContent__Slide input {
      width: 90%;
      height: 50px;
      padding: 10px; }
    .tabsContent__Slide input[type="submit"] {
      background: #4c68b2;
      color: #FFF;
      border: 0; }
    .tabsContent__Slide textarea {
      min-width: 90%;
      max-width: 90%;
      width: 90%;
      padding: 10px; }
  .tabsContent a[href$=".pdf"] {
    float: left;
    width: 30%;
    margin-right: 1.50% !important;
    margin-left: 1.50% !important;
    margin-bottom: 3%;
    height: 450px;
    background: #4c68b2;
    padding-top: 100px;
    padding-left: 30px;
    text-transform: uppercase;
    position: relative;
    color: #FFF !important; }
    .tabsContent a[href$=".pdf"]:hover {
      color: #FFF;
      opacity: 0.8; }
      .tabsContent a[href$=".pdf"]:hover:after {
        display: none !important; }
    .tabsContent a[href$=".pdf"]:after {
      display: none !important; }
    .tabsContent a[href$=".pdf"]:before {
      position: absolute;
      display: block;
      content: 'PDF';
      right: 10px;
      top: 10px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.3); }

.slick-slide {
  outline: none !important; }

.productFormOutbox {
  float: left;
  width: 100%;
  position: relative; }

.productForm {
  position: absolute;
  background: #FFF;
  width: 600px;
  z-index: -10000000000;
  opacity: 0;
  top: -480px;
  padding: 50px;
  padding-bottom: 0;
  transition: opacity 500ms;
  left: -350px; }
  .productForm label {
    font-size: 13px;
    font-weight: lighter; }
  .productForm input {
    margin-bottom: 10px;
    height: 30px;
    width: 100% !important;
    padding: 0 10px !important; }
  .productForm select {
    margin-bottom: 10px;
    height: 30px;
    width: 100% !important;
    border: 1px solid #999;
    padding: 0 10px !important; }
  .productForm input[type="submit"] {
    height: 50px;
    width: 100% !important;
    padding: 0 10px !important; }
  .productForm textarea {
    margin-bottom: 10px;
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important; }
  .productForm__Call {
    border: 1px #4c68b2 solid;
    display: inline-block;
    padding: 0 30px;
    line-height: 46px;
    text-decoration: none;
    letter-spacing: 2.5px;
    font-size: 14px;
    text-transform: uppercase;
    color: #4c68b2;
    position: relative;
    overflow: hidden;
    z-index: 1;
    vertical-align: bottom;
    background: transparent;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
    color: #4c68b2 !important;
    font-size: 14px;
    font-weight: lighter;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 50px; }
    .productForm__Call:hover {
      color: #4c68b2; }
      .productForm__Call:hover:before {
        opacity: 1;
        right: -100px;
        left: -100px; }
    .productForm__Call:before {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      top: -10px;
      bottom: -10px;
      transform: skew(0deg);
      background: #FFF;
      left: 0;
      right: 0;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
      opacity: 0; }
    .productForm__Call:before {
      background: #4c68b2; }
    .productForm__Call:hover {
      color: #FFF !important;
      cursor: pointer; }
  .productForm__Close {
    position: absolute;
    float: right;
    right: 50px;
    transition: 500ms;
    top: 30px; }
    .productForm__Close:hover {
      color: #4c68b2;
      transform: rotate(180deg) scale(1.3);
      cursor: pointer; }

.activeForm {
  opacity: 1;
  z-index: 10000000000; }

.activeBody {
  position: relative;
  z-index: 1;
  float: left;
  width: 100%;
  transition: opacity 500ms; }
  .activeBody:before {
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #000;
    opacity: 0.5;
    z-index: 1000000000;
    position: absolute; }

.siteHeader {
  position: fixed;
  z-index: 10000000;
  float: left;
  width: 100%;
  background: #FFF;
  transition: 300ms; }
  .siteHeader__Top {
    float: left;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background: #464646;
    text-align: right; }
    .siteHeader__Top ul {
      padding: 0;
      margin: 0;
      list-style: none; }
      .siteHeader__Top ul li {
        display: inline-block;
        font-size: 12px;
        color: #FFF;
        position: relative;
        z-index: 1;
        font-weight: lighter; }
        .siteHeader__Top ul li:first-of-type {
          margin-right: 10px; }
          .siteHeader__Top ul li:first-of-type:after {
            position: absolute;
            content: '';
            top: 10px;
            bottom: 10px;
            right: -8px;
            width: 1px;
            background: #FFF;
            z-index: -1; }
        .siteHeader__Top ul li a {
          color: #FFF;
          text-decoration: none; }
  .siteHeader__Logo {
    float: left;
    line-height: 90px;
    height: 90px;
    position: relative;
    transition: 300ms;
    width: 30%;
    text-align: center; }
    .siteHeader__Logo a {
      margin: auto;
      left: 0;
      right: 0; }
    .siteHeader__Logo img.colorLogo {
      max-width: 200px;
      vertical-align: middle;
      line-height: 90px;
      opacity: 1;
      position: relative; }
  .siteHeader__Other {
    float: right;
    height: 90px;
    line-height: 90px; }
    .siteHeader__Other img {
      max-width: 80px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px; }
      .siteHeader__Other img:nth-of-type(1) {
        margin-top: -10px; }
      .siteHeader__Other img:nth-of-type(4) {
        margin-top: -5px; }

.js-SiteHeader--is-Active {
  position: fixed;
  margin-top: -125px; }
  .js-SiteHeader--is-Active a:before {
    top: -50px;
    bottom: auto; }
  .js-SiteHeader--is-Active a:hover:before {
    top: -1px;
    bottom: auto; }
  .js-SiteHeader--is-Active .current-menu-item a:before {
    top: -1px;
    bottom: auto; }

.siteFooter {
  float: left;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 50px 0; }
  .siteFooter ul.socialHome {
    padding: 0;
    margin: 0;
    text-align: left; }
    .siteFooter ul.socialHome li {
      list-style: none;
      display: inline-block;
      line-height: 90px;
      text-align: center; }
      .siteFooter ul.socialHome li a {
        float: left;
        width: 100%;
        height: 100%;
        transition: 300ms;
        color: #FFF; }
        .siteFooter ul.socialHome li a:hover {
          transition: 300ms; }
          .siteFooter ul.socialHome li a:hover i {
            background: #4c68b2;
            color: #FFF;
            transition: 300ms; }
        .siteFooter ul.socialHome li a i {
          font-size: 20px;
          border: 1px solid #4c68b2;
          width: 40px;
          height: 40px;
          line-height: 40px;
          transition: 300ms;
          color: #4c68b2; }
  .siteFooter__Widget {
    float: left;
    width: 24%;
    margin-left: 0.5%;
    margin-right: 0.5%; }
    .siteFooter__Widget h3 {
      margin: 0; }
    .siteFooter__Widget img {
      max-width: 180px; }
    .siteFooter__Widget:first-of-type {
      padding-right: 5%; }

.nav {
  position: relative;
  font-size: 14px;
  letter-spacing: 2.38px;
  float: left;
  text-align: center;
  width: 100%;
  background: #4c68b2;
  overflow: hidden; }
  .nav ul {
    padding: 0;
    margin: 0 auto;
    list-style-type: none;
    text-align: center;
    height: 60px;
    line-height: 60px;
    float: right; }
  .nav li {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    position: relative; }
  .nav a {
    color: #FFF;
    text-transform: uppercase;
    font-size: 1em;
    text-decoration: none;
    line-height: 60px;
    margin: 0;
    display: block;
    position: relative;
    transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
    padding: 0 10px;
    font-weight: bold; }
    .nav a:before {
      content: '';
      position: absolute;
      z-index: 100000000000000;
      height: 5px;
      bottom: -50px;
      left: 10px;
      right: 10px;
      opacity: 1;
      transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
      background: #FFF; }
    .nav a:hover {
      color: #FFF;
      transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
      .nav a:hover:before {
        opacity: 1;
        transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
        left: 10px;
        right: 10px;
        bottom: 1px; }
  .nav .current-menu-item a {
    color: #FFF; }
    .nav .current-menu-item a:before {
      opacity: 1;
      transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
      left: 10px;
      right: 10px;
      bottom: 1px; }

.menu-glavni-izbornik-container {
  float: left;
  width: 100%;
  text-align: center; }
  .menu-glavni-izbornik-container ul {
    width: 100%; }

.mobile {
  display: none !important; }

@media screen and (max-width: 800px) {
  .productForm {
    width: 100%;
    padding: 10px;
    top: -300px;
    left: auto; }
    .productForm__Close {
      top: 10px;
      right: 10px; }
  .mobile {
    display: block !important; }
  .siteHeader {
    position: relative !important;
    float: none !important;
    width: auto !important;
    background-color: transparent !important;
    margin-top: 0 !important;
    overflow: initial; }
    .siteHeader__Logo {
      float: right;
      width: auto;
      background-color: transparent !important;
      padding-right: 10px; }
    .siteHeader__Other {
      height: auto;
      line-height: initial;
      text-align: center;
      float: left;
      width: 100%; }
      .siteHeader__Other img {
        margin: 5px 2.5px;
        max-width: 50px; }
  .nav {
    position: absolute;
    background: #4c68b2;
    transform: translateX(-100%);
    z-index: 1000000000;
    transition: 500ms !important;
    top: 35px;
    overflow: initial;
    height: 100%;
    padding-top: 15px;
    min-height: 100vh; }
    .nav ul {
      height: 100%;
      line-height: 1;
      display: block; }
    .nav li {
      display: table;
      float: none;
      width: auto; }
    .nav a {
      float: none;
      width: auto; }
      .nav a:before {
        display: none !important; }
  .callNav {
    position: absolute;
    right: -55px;
    top: 10px;
    padding: 10px 15px;
    background: #4c68b2;
    cursor: pointer; }
    .callNav i {
      color: #FFF;
      transition: 700ms; }
  .activeNav {
    transform: translateX(0%);
    opacity: 1;
    transition: 500ms !important;
    position: fixed; }
    .activeNav .callNav {
      right: 10px;
      background: #FFF; }
      .activeNav .callNav i {
        color: #4c68b2;
        transform: rotate(180deg); }
        .activeNav .callNav i:before {
          content: "\f00d" !important; }
  .activeForNav .siteHeader__Top {
    position: fixed !important;
    z-index: 1000000000; }
  .activeForNav .siteHeader__Logo {
    top: 35px;
    z-index: 100000000; }
  .activeForNav .siteHeader {
    padding-bottom: 35px; }
  .homeSlider {
    margin-top: 0 !important;
    height: 540px !important; }
    .homeSlider__Slide {
      height: 540px !important; }
    .homeSlider .center {
      height: 540px !important; }
    .homeSlider__Inner h2 {
      font-size: 30px; }
    .homeSlider__Inner h3 {
      font-weight: normal;
      font-size: 18px; }
    .homeSlider .arrowNext, .homeSlider .arrowPrev {
      height: 540px !important;
      line-height: 540px !important; }
  .widgets h2 {
    margin-bottom: 10px; }
  .widgets__Box {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%; }
  .news__Box {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%; }
  .aboutUs__Slider {
    height: 550px; }
  .aboutUs__Slide {
    height: 550px !important; }
  .aboutUs__Content {
    margin-top: -480px;
    margin-bottom: 100px; }
    .aboutUs__Content p {
      font-size: 14px; }
  .siteFooter__Widget {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center !important; }
    .siteFooter__Widget:first-of-type {
      padding-right: 0; }
  .siteFooter ul.socialHome {
    text-align: center !important; }
  .innerImage {
    height: 480px; }
    .innerImage__Slide {
      height: 480px; }
    .innerImage .center {
      height: 480px; }
    .innerImage__Inner {
      width: 80%;
      margin-left: 10%; }
      .innerImage__Inner h2 {
        font-size: 48px; }
  .productImage {
    height: 480px; }
    .productImage__Slide {
      height: 480px; }
    .productImage .center {
      height: 480px; }
    .productImage__Inner {
      width: 80%;
      margin-left: 10%;
      padding: 0; }
      .productImage__Inner h2 {
        font-size: 48px; }
  .blogImage {
    height: 480px; }
    .blogImage__Slide {
      height: 480px; }
    .blogImage .center {
      height: 480px; }
    .blogImage__Inner {
      width: 80%;
      margin-left: 10%; }
      .blogImage__Inner h2 {
        font-size: 48px; }
  .content {
    width: 80%;
    margin-left: 10%; }
    .content__Block {
      width: 100%; }
    .content blockquote {
      width: 100%;
      margin-left: 0;
      margin-right: 0; }
    .content h1 {
      font-size: 40px; }
    .content h2 {
      font-size: 30px; }
    .content h3 {
      font-size: 24px; }
    .content h4 {
      font-size: 20px; }
    .content h5 {
      font-size: 20px; }
    .content h6 {
      font-size: 16px; }
  .productsPage__Box {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%; }
  .productsList__Box {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%; }
  .blog__Box {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%; }
  .singleProduct {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%; }
    .singleProduct__Block {
      width: 100%; }
  .singleSliderNav {
    display: none !important; }
  .tabsBlock {
    display: none; }
  .content input {
    width: 100%;
    height: 40px;
    padding: 0 10px; }
  .content input[type="submit"] {
    background: #4c68b2;
    color: #FFF;
    border: 0; }
  .content textarea {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding: 10px; } }
