.mobile {display: none;}
.homeSlider {float: left;width: 100%;height: 100vh;margin-bottom: 0px !important;margin-top: 100px;
    .center {position: relative;height: 100vh;}
    &__Slide {float: left;height: 100vh; width: 100%;background-size: cover;-webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-repeat: no-repeat;background-position: center top;position: relative;z-index: 1; 
        @include overlay3;
    }
    &__Inner {
        float: left;width: 100%;top: 50%;transform:translateY(-50%);position: relative;text-align: center;padding-left: 50px;padding-right: 50px;
        a {text-decoration: none;
            &:hover {
                h2 {
                    &:before {opacity: 1;}
                }
            }
        }
        h2 {font-weight: 700;font-size: 50px;text-align: center;line-height: 1.1;color: $white;margin: 0 auto;margin-bottom: -300px;transition:300ms;
            &:before {
                position: absolute;content: '';display: block;z-index: -1;left: 0;right: 0;bottom: 0;height: 7px;opacity: 0; background: $brand;transition:300ms;
            }
        }
        h3 {font-weight: 700;font-size: 30px;text-align: center;line-height: 1.1;color: $white;margin-bottom: -300px;transition:300ms;
            &:before {
                position: absolute;content: '';display: block;z-index: -1;left: 0;right: 0;bottom: 0;height: 7px;opacity: 0; background: $brand;transition:300ms;
            }
        }
        a {@include moreButton($white,$white);color: $brand;margin-top: 50px;
            &:before {background: $brand;}
            &:hover {color: $white;}
        }
    }
    .slick-active {
        h2 {margin: 0 auto;transition:300ms;}
        h3 {margin-bottom: 0;transition:300ms;}
    }
}
.arrowPrev, .arrowNext {display: block;position: absolute;top: 0;bottom: 0;width: 60px;height: 100%;}
.arrowPrev {height: 100vh;line-height: 100vh !important; opacity:1; transition:200ms;color: $white;font-size: 80px !important;padding-left: 15px;z-index: 10;
  // &:after {width: 50px;bottom: 45%;top: 45%;left: 10px;position: absolute;content: '';background: alpha($white,0.6);}
  &:hover {opacity:0.8; transition:200ms;}
}

.arrowNext {height: 100vh;line-height: 100vh !important; opacity:1; transition:200ms;color: $white;font-size: 80px !important; opacity:1; transition:200ms;right: 0;text-align: right;padding-right: 15px;z-index: 10;
  // &:after {width: 50px;bottom: 45%;top: 45%;right: 0;position: absolute;content: '';background: alpha($white,0.6);}
  &:hover {opacity:0.8; transition:200ms;}
}

.news {
    float: left;width: 100%;text-align: center;padding: 60px 0;padding-top: 0;
    h2 {font-size: 40px;font-weight: 700;text-align: center;color: $brand;margin: 0;text-transform: uppercase;}
    h5 {font-size: 16px;font-weight: 300;text-align: center;margin-top: 0;color: $gray;}
    &__Box {@include span-columns(4);background: #ededed;
        &:nth-of-type(3n) {margin-right: 0;}
        &--img {float: left;width: 100%;height: 200px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
        &--cnt {float: left;width: 90%;margin-left: 5%;margin-right: 5%;}
        a {text-decoration: none;
            &:hover {
                p {color: $black;}
            }
        }
        h4 {float: left;width: 100%;color: $brand;font-size: 18px;}
        p {color: $gray;font-size: 14px;}
    }
    &__More {
        float: left;width: 100%;
        a {text-decoration: none;font-size: 76px;font-weight: 700;text-align: center;color: $brand;position: relative;z-index: 1;transition:300ms;
            &:before {height: 10px;background: $brand; left: 0;bottom: 20px;right: 100%;position: absolute;content: '';z-index: -1;transition:300ms;}
            &:hover {
                &:before {right: 0;}
            }
        }
    }
}
.addTopPadding {padding-top: 60px !important;}
.page-template-page-news {
    .news__Box {margin-bottom: 3.44828%;}
}

.widgets {
    float: left;width: 100%;text-align: center;padding: 60px 0 0 0;
    h2 {font-size: 40px;font-weight: 700;text-align: center;color: $brand;margin-top: 0;margin-bottom: 0;text-transform: uppercase;}
    h5 {font-size: 16px;font-weight: 300;text-align: center;margin-top: 0;color: $gray;}
    &__Box {float: left;width: 32%;margin-left: 0.5%;margin-right: 0.5%;height: 230px;overflow: hidden;position: relative;padding-top: 30px;

        h4 {float: left;clear: both;width: 100%;text-align: center;color: $gray;position: relative;}
       img {transition:300ms;max-width: 150px;}
        a {float: left;width: 100%;height: 230px;transition:300ms;text-decoration: none;
            
            &:hover {
                img {transform: scale(1.1)}    
            }
        }
    }
    
}

    .sectionTitle {float: left;width: 100%;
        h2 {font-size: 40px;font-weight: 700;text-align: center;color: $brand;text-transform: uppercase;}
        }
.acf-map {
    float: left;width: 100%;height: 500px;
}
.references {
    float: left;width: 100%;text-align: center;padding: 0;
    h2 {font-size: 40px;font-weight: 700;text-align: center;color: $brand;margin: 0;text-transform: uppercase;}
  &__Box {float: left;width: 25%;height: 190px !important;
    
    &--name {float: left;width: 100%;text-align: center;height: 200px;position: relative;
        img {max-width: 180px;text-align: center;margin: auto;position: relative;top: 50%;transform:translateY(-50%);opacity: 0.5;transition:300ms;}
        h4 {float: left;clear: both;width: 100%;margin-bottom: 0;color: $brand;position: absolute;bottom: 0;transition:300ms;line-height: 1.1;}
        h6 {float: left;clear: both;width: 100%;margin-top: 0;text-transform: uppercase;color: $brand;transition:300ms;position: absolute;bottom: -50px;}
    }
     a {float: left;width: 100%;height: 300px;transition:300ms;text-decoration: none;z-index: 100;position: relative;
            &:hover {
                img {opacity: 1;max-width: 95px;}
                h4 {color: $brand;}
                h6 {color: $brand;}
                p {opacity: 1;}
            }
        }
  }
  &__More {
    a {@include moreButton($brand, $brand); color: $white;right: -5px;}
  }
}
.arrowPrev-reference, .arrowNext-reference {display: block;position: absolute;top: 50%;width: 60px;z-index: 10000;transform:translateY(-50%);
    &:after {display: none;}
    
  }
.arrowPrev-reference {opacity:1; transition:200ms;color: $brand;font-size: 40px;padding-left: 15px;left: -20px;
  &:hover {opacity:0.8; transition:200ms;}
  &:before {line-height: 300px;font-size: 26px !important;}
}

.arrowNext-reference {transition:200ms;color: $brand;font-size: 40px; opacity:1; transition:200ms;right: 0px;text-align: right;padding-right: 15px;
  &:hover {opacity:0.8; transition:200ms;}
  &:before {line-height: 300px;font-size: 26px !important;}
}
.aboutUs {
    float: left;width: 100%;padding: 60px 0 0 0;
    &__Slider {float: left;width: 100%;height: 450px;background: $brand;margin-bottom: 0 !important;}
    &__Slide {float: left;width: 100%;height: 450px !important;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;z-index: 1;
        &:before {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $brand;opacity: 0.7;}
    }
    &__Content {
        float: left;width: 100%;text-align: center;height: 450px;margin-top: -380px;
        h2 {font-size: 40px;font-weight: 700;text-align: center;color: $white;margin: 0;text-transform: uppercase;}
        p {color: $white;font-size: 18px;}
    }
}