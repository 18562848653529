.productsPage {float: left;width: 100%;position: relative;z-index: 1;
 
 &__Wrap {float: left; width: 100%; background: $white;}
 &__Box {float: left;@include span-columns(6);height: 350px; background-size: cover;background-repeat: no-repeat; background-position: center center;position: relative; z-index: 1;transition: 300ms $ease-in-out-quint;margin-bottom: 50px;
    &:after {position: absolute;content: '';top: 0;right: 0;bottom: 0;left: 0; background: alpha($white,0.75); z-index: -1;transition: 300ms $ease-in-out-quint;}
    &:nth-of-type(2n) {margin-right: 0;}
    h4 {font-size: 14px; color: $gray-light;margin: 0;transition: 300ms $ease-in-out-quint;font-weight: 300;}
    h3 {color: $brand; font-size: 20px;margin: 0;transition: 300ms $ease-in-out-quint;text-transform: uppercase;word-spacing:500px;}
    i {color: $brand;font-size: 40px;}
    a {float: left;width: 100%;height: 300px;text-decoration: none;position: relative; padding: 0 50px;transition: 300ms $ease-in-out-quint;}
   &--wrapText {position: relative;top: 50%;transform:translateY(-50%);float: left;width: 50%;text-align: left;}
   &--wrapRight {position: relative;top: 50%;transform:translateY(-50%);float: left;width: 50%;text-align: right;}
   &:hover {transition: 300ms $ease-in-out-quint;
    a {padding-left: 70px;padding-right: 70px;transition: 300ms $ease-in-out-quint;}
    h4 {color: $white;transition: 300ms $ease-in-out-quint;}
    h3 {color: $white;transition: 300ms $ease-in-out-quint;}
    i { color: $white;transform:rotate(180deg);}
    &:after {transition: 300ms $ease-in-out-quint; background: alpha($brand,0.75);}
   }
 }
}

.productsList {
    float: left;width: 100%;text-align: center;padding-bottom: 50px;
    &__Box {@include span-columns(3);height: 350px;background-size: 90% auto;background-repeat: no-repeat; background-position: center center; position: relative;z-index: 1;overflow: hidden;transition:300ms;
      img {transition:300ms;}
      &:hover {
                .productsList__Box--image {transform:scale(1.05);}
            }
        &:nth-of-type(4n) {margin-right: 0;}
        h4 {float: left;clear: both;width: 100%;text-align: center;color: $brand;font-size: 20px;font-weight: 700;position: relative;transition:300ms;opacity: 1;}
        a {float: left;width: 100%;height: 350px;transition:300ms;text-decoration: none;position: relative;
           
            
        }
        p {color: $white; font-size: 13px;transition:300ms;position: relative;padding: 20px;text-align: left;float: left;padding-top: 0;opacity: 0;transition:300ms;}
        &--image {float: left;width: 100%;height: 200px;background-size: cover;background-repeat: no-repeat;background-position: center center;transition:300ms;}
        &--content {float: left;width: 100%;}
    }
}

.singleProduct {
  float: left;width: 100%;padding-bottom: 50px;
  &__Block {@include span-columns(6);
    &--content {
  h1 {font-size: 60px; font-weight: 900;color: $brand;line-height: 1.1;margin-top: 0;}
  h2 {font-size: 50px; font-weight: 900;color: $brand;line-height: 1.1;margin-top: 0;}
  h3 {font-size: 40px; font-weight: 700;color: $brand;line-height: 1.1;margin-top: 0;}
  h4 {font-size: 30px; font-weight: 500;color: $brand;line-height: 1.1;margin-top: 0;}
  h5 {font-size: 20px; font-weight: 500;color: $brand;line-height: 1.1;margin-top: 0;}
  h6 {font-size: 16px; font-size: 700;color: $gray;line-height: 1.1; text-transform: uppercase;margin-top: 0;}
  li {color: $gray-light;font-weight: 300;}
  mark {background: alpha($gray-light,0.3);}
  blockquote {background: alpha($gray-light,0.3); font-weight: 300; font-size: 14px; padding: 20px;}
  p {font-size: 16px;font-weight: 300;color: alpha($gray, 0.8);}
  a {text-decoration: none; color: $brand;position: relative;z-index: 1;transition:300ms;
    &:after {content: '';position: absolute;left: 0;right: 0;bottom: -10px;height: 1px;background: $brand;z-index: -1;opacity: 0;transition:300ms;}
    &:hover {color: $black;transition:300ms;
      &:after {opacity: 1;bottom: 0px;transition:300ms;}
    }
  }
  input {width: 90%; height: 50px;padding: 10px;}
  input[type="submit"] {background: $brand;color: $white;border: 0;}
  textarea {min-width: 90%;max-width: 90%;width: 90%;padding: 10px;}
  }
}

}

.singleSlider {
  float: left;width: 100%;height: 400px;
  &__Slide {float: left;width: 100%;height: 400px !important;min-height: 400px !important;background-size: auto 100%;background-repeat: no-repeat
  ;background-position: center center;}
}
.singleSliderNav {
  float: left;height: 150px !important;width: 100%;
  .slick-list {padding: 0 !important;}
  &__Slide {float: left;height: 150px !important;margin-left: 0.5%;margin-right: 0.5%;background-size: auto 100%;background-repeat: no-repeat;background-position: center center;opacity: 0.3;}
  .singleSliderNav__Slide.slick-slide.slick-current.slick-center {opacity: 1;}
}
.arrowPrev-product-single, .arrowNext-product-single {display: block;position: absolute;top: 0;bottom: 0;width: 60px;height: 100%;z-index: 10000;
    
  }
.arrowPrev-product-single {opacity:1; transition:200ms;color: $white;font-size: 40px;padding-left: 15px;
  &:hover {
    &:after {opacity: 0.5;}
  }
  &:before {line-height: 400px;font-size: 35px !important;color: $brand;z-index: 100;}
  &:after {left: -1px;width: 60px;background: $white;position: absolute;top: 0;bottom: 0;content: '';opacity: 0;transition:300ms;z-index: 10;}
}

.arrowNext-product-single {transition:200ms;color: $white;font-size: 40px; opacity:1; transition:200ms;right: 10px;text-align: right;padding-right: 15px;
  &:hover {
    &:after {opacity: 0.5;}
  }
  &:before {line-height: 400px;font-size: 35px !important;color: $brand;z-index: 100;}
  &:after {right: -10px;width: 60px;background: $white;position: absolute;top: 0;bottom: 0;content: '';opacity: 0;transition:300ms;z-index: 10;}
}
.tabsBlock {
  float: left;width: 100%;background: #ededed;padding-bottom: 100px;
}
.tabsNav {
  float: left;width: 100%;background: $white;
  .slick-slider {margin-bottom: 0 !important;}
  &__Slide {float: left;width: 25%;background: $white;text-align: center;
      &:hover {cursor: pointer;}
      h4 {font-weight: 300;font-size: 24px;}
  }
  .tabsNav__Slide.slick-slide.slick-current.slick-center {background: #ededed !important;color: $brand !important;}
}
.tabsContent {
  float: left;width: 100%;background: #ededed;padding-top: 60px;
  .slick-slider {margin-bottom: 0 !important;}
  &__Slide {float: left;width: 100%;background: #ededed;color: alpha($gray, 0.8);
    h1 {font-size: 60px; font-weight: 900;color: $brand;line-height: 1.1;margin-top: 0;}
    h2 {font-size: 50px; font-weight: 900;color: $brand;line-height: 1.1;margin-top: 0;}
    h3 {font-size: 40px; font-weight: 700;color: $brand;line-height: 1.1;margin-top: 0;}
    h4 {font-size: 30px; font-weight: 500;color: $brand;line-height: 1.1;margin-top: 0;}
    h5 {font-size: 20px; font-weight: 500;color: $brand;line-height: 1.1;margin-top: 0;}
    h6 {font-size: 16px; font-size: 700;color: $gray;line-height: 1.1; text-transform: uppercase;margin-top: 0;}
    li {color: $gray-light;font-weight: 300;}
    mark {background: alpha($gray-light,0.3);}
    blockquote {background: alpha($gray-light,0.3); font-weight: 300; font-size: 14px; padding: 20px;}
    p {font-size: 16px;font-weight: 300;color: alpha($gray, 0.8);}
    a {text-decoration: none; color: $brand;position: relative;z-index: 1;transition:300ms;
      &:after {content: '';position: absolute;left: 0;right: 0;bottom: -10px;height: 1px;background: $brand;z-index: -1;opacity: 0;transition:300ms;}
      &:hover {color: $black;transition:300ms;
        &:after {opacity: 1;bottom: 0px;transition:300ms;}
      }
    }
    input {width: 90%; height: 50px;padding: 10px;}
    input[type="submit"] {background: $brand;color: $white;border: 0;}
    textarea {min-width: 90%;max-width: 90%;width: 90%;padding: 10px;}
  }
  a[href$=".pdf"] {
    float: left;width: 30%;margin-right: 1.50% !important;margin-left: 1.50% !important;margin-bottom: 3%; height: 450px; background: $brand;padding-top: 100px;padding-left: 30px; text-transform: uppercase;position: relative;color: $white !important;
    &:hover {color: $white;opacity: 0.8;
      &:after {display: none !important;}
    }
    &:after {display: none !important;}
    &:before {
      position: absolute;display: block;content: 'PDF';right: 10px;top: 10px;text-transform: uppercase;color: alpha($white,0.3);
    }
  }
}
.slick-slide {
  outline: none !important;
}
.productFormOutbox {float: left;width: 100%;position: relative;}
.productForm {position: absolute;background: $white;width: 600px;z-index: -10000000000;opacity: 0;top: -480px;padding: 50px;padding-bottom: 0;transition:opacity 500ms;left: -350px;
  label {font-size: 13px;font-weight: lighter;}
  input {margin-bottom: 10px;height: 30px; width: 100% !important; padding: 0 10px !important;}
  select {margin-bottom: 10px;height: 30px; width: 100% !important;border: 1px solid $gray-light; padding: 0 10px !important;}
  input[type="submit"] {height: 50px; width: 100% !important; padding: 0 10px !important;}
  textarea {margin-bottom: 10px; width: 100% !important;min-width: 100% !important;max-width: 100% !important;}
  &__Call {@include moreButton($brand, transparent);color: $brand !important;font-size: 14px;font-weight: lighter;padding-top: 10px;padding-bottom: 10px;margin-top: 50px;
    &:before {background: $brand;}
    &:hover {color: $white !important;cursor: pointer;}
  }
  &__Close {position: absolute;float: right;right: 50px;transition:500ms;top: 30px;
    &:hover {color: $brand;transform:rotate(180deg) scale(1.3);cursor: pointer;}
  }
}
.activeForm {opacity: 1;z-index: 10000000000;}
.activeBody {position: relative;z-index: 1;float: left;width: 100%;transition:opacity 500ms;
  &:before {content: '';top: 0;bottom: 0;right: 0;left: 0;background: $black;opacity: 0.5;z-index: 1000000000;position: absolute;}
}