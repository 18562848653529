// COLORS
  $brand2: #8C001A;
  $brand: #4c68b2;

  $white: #FFF;
  $gray: #777; 
  $black: #000;
  $gray-light: #999;
  $gray-dark: #464646;
  
  $social: #6d6b64;
  $facebook: #3B5997;
  $twitter: #4099FF;
  
  // TYPE
  // @import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic&subset=latin,latin-ext);

  // TYPE
  // @import url(https://fonts.googleapis.com/css?family=Poppins:400,300,500,600,700&subset=latin,latin-ext);
  // @import url('https://fonts.googleapis.com/css?family=Raleway:100,300,300i,400,400i,500,500i,700,700i&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&subset=latin-ext');
  $font-size: 16px;
  $line-height: 1.5;
  $default: 'Open Sans', sans-serif;

  // $light: 100;
  $regular: 400;
  $mediumbold: 500;
  $semibold: 600;
  $bold: 700;
  $ultra: 900;

  $xxsmall: em(10px);
  $xsmall: em(12px);
  $small: em(14px);
  $medium: em(16px);
  $large: em(18px);
  $xlarge: em(20px);
  $xxlarge: em(24px);
  
// DEFAULTS
  body { font: #{$font-size}/#{$line-height} $default; }
  

  @function alpha($color: $black, $opacity: 0.5) { 
    $alpha-color-value: rgba($color, $opacity); 
    @return $alpha-color-value;
  }