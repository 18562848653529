.siteFooter { float: left; width: 100%;overflow: hidden;position: relative;padding: 50px 0;
  
   ul.socialHome {padding: 0;margin: 0;text-align: left;
        li {list-style: none;display: inline-block;line-height: 90px;text-align: center;
          a {float: left;width: 100%;height: 100%;transition:300ms;color: $white;
            &:hover {transition:300ms;
              i {background: $brand;color: $white;transition:300ms;}
            }
            i {font-size: 20px;border: 1px solid $brand;width: 40px;height: 40px;line-height: 40px;transition:300ms;color: $brand;}
          }
        }
      }
  &__Widget {float: left;width: 24%;margin-left: 0.5%;margin-right: 0.5%;
    h3 {margin: 0;}
    img {max-width: 180px;}
    &:first-of-type {
      padding-right: 5%;
    }
  }
}