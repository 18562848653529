.nav { position: relative; font-size: 14px; letter-spacing: 2.38px;float: left;text-align: center;width: 100%;background: $brand;overflow: hidden;
  ul { padding: 0; margin: 0 auto; list-style-type: none; text-align: center; height: 60px;line-height: 60px;float: right;}
  li { display: inline-block; text-align: center; vertical-align: middle; position: relative;}
  a { color: $white; text-transform: uppercase; font-size: 1em; text-decoration: none; line-height: 60px; margin: 0; display: block; position: relative;transition: 300ms $ease-in-out-quint;padding: 0 10px;font-weight: bold;
  
    &:before { content: ''; position: absolute; z-index: 100000000000000;  height: 5px; bottom: -50px; left: 10px;right: 10px;opacity: 1;transition: 300ms $ease-in-out-quint; background: $white;
     }
    &:hover {color: $white;transition: 300ms $ease-in-out-quint;
      &:before { opacity: 1;transition: 300ms $ease-in-out-quint;left: 10px; right: 10px; bottom: 1px; }
    }
  }
  .current-menu-item {
    a {color: $white;
       &:before {opacity: 1;transition: 300ms $ease-in-out-quint;left: 10px;right: 10px; bottom: 1px; }
    }
  }
}
.menu-glavni-izbornik-container {
    float: left;width: 100%;text-align: center;
    ul {width: 100%;}
}
