.contactBlock {margin-top: 60px;}
.innerSlider {
    float: left; width: 100%;overflow: hidden;height: 900px;position: relative;background-size: cover;background-repeat: no-repeat;background-position: center top;  background-image: url(../images/bg-onama-top.png);
    &__Left {width: 30%;padding-left: 15%;text-align: right;padding-right: 7%;position: relative;display: table-cell;vertical-align: bottom; height: 550px;
      img {max-width: 100px;}
      h3 {color: $white;font-size: 40px; text-transform: uppercase; line-height: 1.2;margin-bottom:0;}
    }
    &__Right {width: 40%;padding-right: 10%;padding-left: 2%;position: relative;display: table-cell;vertical-align: bottom; height: 550px;right: 5%;
      p {text-align: justify;color: $white;margin: 0;line-height: 1.2;font-weight: 300;}
      h4 {color: $white;margin: 0;margin-top: 10px; text-transform: uppercase;}
      
    }
    &__Statistics {background: alpha($black,0.7); float: left;width: 100%;height: 250px;margin-top: 50px;
      .circliful {float: right; font-weight: 300;}
      .circle-text {color: $white;font-weight: 300;
        i {display: none;}
      }
      .filled {
        .circle-text {color: $black;}
      }
      .circle-info {top: 150px; color: $white;line-height: 1.2 !important;}
      &--wrap {height: 250px;float: left;width: 100%;position: relative;z-index: 1;
        &:before { content: ''; position: absolute; z-index: -1; width: 0; height: 0; top: 0px; left: 10%;  border-style: solid; border-width: 20px 20px 0 20px; border-color: #cf212e transparent transparent; transform: translateX(-50%);opacity: 1;transition: 300ms $ease-in-out-quint;
    }
      }
      &--left {float: left;width: 30%; position: relative;top: 50%;transform:translateY(-50%);color: $white;font-weight: 300;
        
      }
      &--right {float: left;width: 70%;height: 200px;padding-top: 25px;}
    }
}
.page-template-page-homepage{
  .menu {
    li {
      &:first-of-type {
        a {
          &:before {opacity: 1;transition: 300ms $ease-in-out-quint;}
        }
      }
    }
  }
}
.page-template-page-about {
  .menu {
    li {
      &:first-of-type {
        a {
          &:before {opacity: 1;transition: 300ms $ease-in-out-quint;}
        }
      }
    }
  }
}


.contactPage {height: 500px;float: left;width: 100%;position: relative;
  &__Right {float: right;height: 500px;width: 1000px;position: absolute;top: 0;right: 0;background-size: cover;background-repeat: no-repeat;background-position: center top;  background-image: url(../images/bg-onama-contact.png);}
  &__Inner {width: 60%;float: right;transform:translateY(-50%);top: 50%;position: relative;
      img {max-width: 90px;}
      ul {padding: 0;margin: 0;margin-top: 20px;
        li {list-style: none;color: $white;text-transform: uppercase;
          a {text-decoration: none; color: $white; margin-left: 5px;margin-right: 5px;}
          a.mail {color: $brand; text-transform: lowercase;}
        }
      }
      ul.social {padding: 0;margin: 0;margin-top: 40px;
      li {
        list-style-type: none; text-align: left;display: inline-block;color: $white;height: 30px;width: 30px;border: 1px solid $white; border-radius: 100%; text-align: center; line-height: 30px;
        i {font-size: 16px;}
      }
    }
  }
}
.map {
  float: left;width: 100%; height: 500px;transform:translateX(-10%);
}