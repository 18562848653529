.innerImage {float: left;width: 100%;height: 600px;margin-bottom: 60px;
    .center {height: 600px;}
    &__Slide {float: left;height: 600px; width: 100%;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;z-index: 1; 
        &:after {
            position: absolute;top: 0;right: 0;bottom: 0;left: 0;z-index: -1;content: '';@include gradient;
        }
    }
    &__Inner {
        float: left;width: 100%;top: 50%;transform:translateY(-50%);position: relative;
        a {text-decoration: none;
            &:hover {
                h2 {
                    &:before {opacity: 1;}
                }
            }
        }
        h2 {font-weight: 700;font-size: 80px;text-align: left;line-height: 1.1;color: $white;transition:300ms;margin-bottom: 0;
        }
    }
}
.blogImage {float: left;width: 100%;height: 600px;
    .center {height: 600px;}
    &__Slide {float: left;height: 600px; width: 100%;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;z-index: 1; 
        &:after {
            position: absolute;top: 0;right: 0;bottom: 0;left: 0;z-index: -1;content: '';@include gradient;
        }
    }
    &__Inner {
        float: left;width: 100%;top: 50%;transform:translateY(-50%);position: relative;
        a {text-decoration: none;
            &:hover {
                h2 {
                    &:before {opacity: 1;}
                }
            }
        }
        h2 {font-weight: 700;font-size: 80px;text-align: left;line-height: 1.1;color: $white;transition:300ms;margin-bottom: 0;
        }
    }
}

.productImage {float: left;width: 100%;height: 600px;margin-bottom: 60px;
    
    &__Slide {float: left;height: 600px; width: 100%;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;z-index: 1; 
        &:after {
            position: absolute;top: 0;right: 0;bottom: 0;left: 0;z-index: -1;content: '';@include gradient;
        }
    }
    &__Inner {
        float: left;width: 100%;top: 50%;transform:translateY(-50%);position: relative;padding: 50px;
        a {text-decoration: none;
            &:hover {
                h2 {
                    &:before {opacity: 1;}
                }
            }
        }
        h2 {font-weight: 700;font-size: 80px;text-align: left;line-height: 1.1;color: $white;transition:300ms;margin-bottom: 0;
        }
    }
}

.errorImage {float: left;width: 100%;height: 600px;margin-top: 130px;
    .center {position: relative;height: 600px;}
    &__Slide {float: left;height: 600px; width: 100%;background-size: auto 100%;background-repeat: no-repeat;background-position: right center;position: relative;z-index: 1; background-color: $brand;
        &:after {
            position: absolute;top: 0;right: 0;bottom: 0;left: 0;z-index: -1;content: '';@include gradient;
        }
    }
    &__Inner {
        float: left;width: 100%;max-width: 600px;top: 50%;transform:translateY(-50%);position: relative;
        a {text-decoration: none;
            &:hover {
                h2 {
                    &:before {opacity: 1;}
                }
            }
        }
        h2 {font-weight: 700;font-size: 80px;text-align: left;line-height: 1.1;color: $white;transition:300ms;
          span {display: block;font-size: 38px;font-weight: 300;}
        }
    }
}

.content {
  margin: auto;float: left;width: 100%;
  &__Block {float: left;width: 50%;padding: 0 20px;}
  h1 {font-size: 60px; font-weight: 900;color: $brand;line-height: 1.1;}
  h2 {font-size: 50px; font-weight: 900;color: $brand;line-height: 1.1;}
  h3 {font-size: 40px; font-weight: 700;color: $brand;line-height: 1.1;}
  h4 {font-size: 30px; font-weight: 500;color: $brand;line-height: 1.1;}
  h5 {font-size: 20px; font-weight: 500;color: $brand;line-height: 1.1;}
  h6 {font-size: 16px; font-size: 700;color: $gray;line-height: 1.1; text-transform: uppercase;}
  li {color: $gray-light;font-weight: 300;}
  mark {background: alpha($gray-light,0.3);}
  blockquote {background: alpha($gray-light,0.3); font-weight: 300; font-size: 14px; padding: 20px;}
  p {font-size: 18px;font-weight: 300;color: alpha($gray, 0.8);}
  a {text-decoration: none; color: $brand;position: relative;z-index: 1;transition:300ms;
    &:after {content: '';position: absolute;left: 0;right: 0;bottom: -10px;height: 1px;background: $brand;z-index: -1;opacity: 0;transition:300ms;}
    &:hover {color: $black;transition:300ms;
      &:after {opacity: 1;bottom: 0px;transition:300ms;}
    }
  }
  input {width: 90%; height: 50px;padding: 10px;}
  input[type="submit"] {background: $brand;color: $white;border: 0;}
  textarea {min-width: 90%;max-width: 90%;width: 90%;padding: 10px;}

}
.blog {
    float: left;width: 100%;text-align: center;padding-bottom: 70px;margin-top: 60px;
    h2 {font-size: 76px;font-weight: 700;text-align: center;color: $brand;margin-top: 0;margin-bottom: 100px;}
    h5 {font-size: 16px;font-weight: 300;text-transform: uppercase;text-align: center;margin-top: 0;margin-bottom: 60px;color: $white;}
    &__Box {@include span-columns(3);@include overlay;height: 400px;background-size: cover;background-repeat: no-repeat; background-position: center center;margin-bottom: 3.44828%;
        &:nth-of-type(3n) {margin-right: 0;}
        &:nth-of-type(2n) {@include span-columns(6);}
        &:nth-of-type(6n) {@include span-columns(3);margin-right: 0;}
        h4 {float: left;clear: both;width: 100%;text-align: left;padding-left: 20px;margin-bottom: 0;color: $white;}
        h6 {float: left;clear: both;width: 100%;text-align: left;padding-left: 20px;margin-top: 0;text-transform: uppercase;color: $white;transition:300ms;}
        a {float: left;width: 100%;height: 400px;transition:300ms;text-decoration: none;
            &:before {transition:300ms;content: "";display: block;position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;z-index: -1;opacity: 0;}
            &:hover {
                h6 {color: $brand;}
                p {opacity: 1;}
                &:before {opacity: 0.5;}
            }
        }
        p {color: $white; font-size: 13px;transition:300ms;position: relative;padding: 20px;text-align: left;float: left;padding-top: 0;opacity: 0;transition:300ms;}
    }
    &__More {
        float: left;width: 100%;
        a {text-decoration: none;font-size: 76px;font-weight: 700;text-align: center;color: $brand;position: relative;z-index: 1;transition:300ms;
            &:before {height: 10px;background: $brand; left: 0;bottom: 20px;right: 100%;position: absolute;content: '';z-index: -1;transition:300ms;}
            &:hover {
                &:before {right: 0;}
            }
        }
    }
}