.siteHeader {position: fixed;z-index: 10000000;float: left;width: 100%;background: $white;transition:300ms;
  &__Top {
    float: left;width: 100%;height: 35px;line-height: 35px;background: $gray-dark;text-align: right;
    ul {padding: 0;margin: 0;list-style: none;
      li {display: inline-block;font-size: 12px;color: $white;position: relative;z-index: 1;font-weight: lighter;
        &:first-of-type {margin-right: 10px;
          &:after {position: absolute;content: '';top: 10px;bottom: 10px;right: -8px;width: 1px;background: $white;z-index: -1;}
        }
        a {color: $white;text-decoration: none;}
      }
    }
  }
  &__Logo {float: left;line-height: 90px;height: 90px;position: relative;transition:300ms;width: 30%;text-align: center;
    a {margin: auto;left: 0;right: 0;}
    img.colorLogo {max-width: 200px;vertical-align: middle;line-height: 90px;opacity: 1;position: relative;}
  }
  &__Other {float: right;height: 90px;line-height: 90px;
      img {max-width: 80px;display: inline-block;vertical-align: middle;margin: 0 10px;
        &:nth-of-type(1) {margin-top: -10px;}
        &:nth-of-type(4) {margin-top: -5px;}
      }
  }

}

.js-SiteHeader {
    &--is-Active {position: fixed;margin-top: -125px;
      a {
        &:before {top: -50px;bottom: auto;}
        &:hover {
          &:before {top: -1px;bottom: auto;}
        }
      }
      .current-menu-item { 
        a {
          &:before {top: -1px;bottom: auto;}
        }
      }
    }
}