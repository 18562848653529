.mobile {display: none !important;}
@media screen and (max-width: 800px) { 
.productForm {width: 100%;padding: 10px;top: -300px;left: auto;
    &__Close {top: 10px;right: 10px;}
}
.mobile {display: block !important;}

.siteHeader {position: relative !important;float: none !important;width: auto !important;background-color: transparent !important;margin-top: 0 !important;overflow: initial;
    &__Logo {float: right;width: auto;background-color: transparent !important;padding-right: 10px;}
    &__Other {height: auto;line-height:initial;text-align: center;float: left;width: 100%;
      img {margin: 5px 2.5px;max-width: 50px;}
    }
}
.nav {position: absolute;background: $brand;transform:translateX(-100%);z-index: 1000000000;transition:500ms !important;top: 35px;overflow: initial;height: 100%;padding-top: 15px;min-height: 100vh;
  ul {height: 100%;line-height: 1;display: block;}
  li {display: table;float: none;width: auto;}
  a {float: none;width: auto;
    &:before {display: none !important;}
  }
}
.callNav {position: absolute;right: -55px;top: 10px;padding: 10px 15px;background: $brand;cursor: pointer;
  i {color: $white;transition:700ms;}
}
.activeNav {transform:translateX(0%);opacity: 1;transition:500ms !important;position: fixed;
  .callNav {right: 10px;background: $white;
    i {color: $brand;transform:rotate(180deg);
      &:before {content: "\f00d" !important;}
    }
  }
}
.activeForNav {
    .siteHeader__Top {position: fixed !important;z-index: 1000000000;}
    .siteHeader__Logo {top: 35px;z-index: 100000000;}
    .siteHeader {padding-bottom: 35px;}
}
.homeSlider{margin-top: 0 !important;height: 540px !important;
    &__Slide {
        height: 540px !important;
    }
    .center {height: 540px !important;}
    &__Inner{ 
        h2 {font-size: 30px;}
        h3 {font-weight: normal;font-size: 18px;}
    }
    .arrowNext, .arrowPrev {height: 540px !important;line-height: 540px !important;}
}

.widgets{
  h2 {margin-bottom: 10px;}
  &__Box {width: 80%;margin-left: 10%;margin-right: 10%;}
}
.news{
  &__Box {width: 80%;margin-left: 10%;margin-right: 10%;margin-bottom: 10%;}
}
.aboutUs{
    &__Slider {
        height: 550px;
    }
    &__Slide {
        height: 550px !important;
    }
    &__Content {
        margin-top: -480px;margin-bottom: 100px;
        p {font-size: 14px;}
    }
}

.siteFooter {
    &__Widget {
        width: 90%;margin-left: 5%;margin-right: 5%;text-align: center !important;
        &:first-of-type {padding-right: 0;}
    }
    ul.socialHome {text-align: center !important;}
}

.innerImage {height: 480px;
    &__Slide {height: 480px;}
    .center {height: 480px;}
  &__Inner {width: 80%;margin-left: 10%;
h2 {font-size: 48px;}
  }
}
.productImage {height: 480px;
    &__Slide {height: 480px;}
    .center {height: 480px;}
  &__Inner {width: 80%;margin-left: 10%;padding: 0;
h2 {font-size: 48px;}
  }
}
.blogImage {height: 480px;
    &__Slide {height: 480px;}
    .center {height: 480px;}
  &__Inner {width: 80%;margin-left: 10%;
h2 {font-size: 48px;}
  }
}

.content {width: 80%;margin-left: 10%;
  &__Block {width: 100%;}
  blockquote {width: 100%;margin-left: 0;margin-right: 0;}
  h1 {font-size: 40px;}
  h2 {font-size: 30px;}
  h3 {font-size: 24px;}
  h4 {font-size: 20px;}
  h5 {font-size: 20px;}
  h6 {font-size: 16px;}
}
.productsPage {

  &__Box {width: 80%;margin-left: 10%;margin-right: 10%;margin-bottom: 10%;}
}
.productsList {

  &__Box {width: 80%;margin-left: 10%;margin-right: 10%;margin-bottom: 10%;}
}

.blog {

  &__Box {width: 80%;margin-left: 10%;margin-right: 10%;margin-bottom: 10%;}
}

.singleProduct {width: 80%;margin-left: 10%;margin-right: 10%;
  &__Block {width: 100%;}
}
.singleSliderNav {display: none !important;}
.tabsBlock {display: none;}
.content {
  input {width: 100%; height: 40px;padding: 0 10px;}
  input[type="submit"] {background: $brand;color: $white;border: 0;}
  textarea {min-width: 100%;max-width: 100%;width: 100%;padding: 10px;}

}

}